












































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { CreateCommunityViewModel } from '../../viewmodels/create-community-viewmodel'

@Observer
@Component({
  components: {
    draggable,
    'contributor-append-input': () =>
      import('@/modules/community-organize/components/forms/contributor-append-input.vue'),
  },
})
export default class Contributor extends Vue {
  @Prop({ default: false }) isContributorDao

  @Inject({}) vm!: CreateCommunityViewModel
}
